import type { BreadCrumbRoute } from "types/menu";
import { MenuList } from "types/menu";
import {
  ApartmentOutlined,
  BarChartOutlined,
  CodeOutlined,
  DashboardOutlined,
  EditOutlined,
  HomeOutlined,
  RobotOutlined,
  SettingOutlined,
  ShareAltOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import type { Authority } from "types/user";
import { ADMIN } from "assets/constants/string";

export const iconMap = {
  home: HomeOutlined,
  report: BarChartOutlined,
  demo: DashboardOutlined,
  service: SettingOutlined,
  dsoc: ShareAltOutlined,
  manage: EditOutlined,
  log: CodeOutlined,
  blog: RobotOutlined,
  product: ShoppingOutlined,
  cubot: ApartmentOutlined,
} as const;

export const breadCrumbRoutes: BreadCrumbRoute[] = [
  {
    path: "/home",
    icon: "home",
    name: "home",
  },
  {
    path: "/report",
    icon: "report",
    name: "report",
  },
  {
    path: "/report/view",
    name: "report.view",
  },
  {
    path: "/report/revenue",
    name: "report.revenue",
  },
  {
    path: "/report/conversion",
    name: "report.conversion",
  },
  {
    path: "/report/daily",
    name: "report.daily",
  },
  {
    path: "/report/section",
    name: "report.section",
  },
  {
    path: "/product-report",
    icon: "product",
    name: "product-report",
  },
  {
    path: "/demo",
    icon: "demo",
    name: "demo",
  },
  {
    path: "/demo/rec",
    name: "demo.rec",
  },
  {
    path: "/demo/rts",
    name: "demo.rts",
  },
  {
    path: "/demo/persona",
    name: "demo.persona",
  },
  {
    path: "/demo/dsoc",
    name: "demo.dsoc",
  },
  {
    path: "/blog-automation",
    name: "blog-automation",
    icon: "blog",
  },
  {
    path: "/blog-automation/tools",
    name: "blog-automation.tools",
  },
  {
    path: "/blog-automation/labelTemplate",
    name: "blog-automation.labelsTemplate",
  },
  {
    path: "/service",
    icon: "service",
    name: "service",
  },
  {
    path: "/service/rec-exclude",
    name: "service.rec-exclude",
  },
  {
    path: "/service/api-key",
    name: "service.api-key",
  },
  {
    path: "/dsoc",
    icon: "dsoc",
    name: "dsoc",
  },
  {
    path: "/dsoc/campaign",
    name: "dsoc.campaign",
  },
  {
    path: "/dsoc/campaign/:cuid",
    name: "dsoc.campaign.cuid",
  },
  {
    path: "/dsoc/designer",
    name: "dsoc.designer",
  },
  {
    path: "/dsoc/designer/:cuid",
    name: "dsoc.designer.cuid",
  },
  {
    path: "/dsoc/selector",
    name: "dsoc.selector",
  },
  {
    path: "/dsoc/selector/new",
    name: "dsoc.selector.new",
  },
  {
    path: "/dsoc/operator",
    name: "dsoc.operator",
  },
  {
    path: "/dsoc/operator/:cuid",
    name: "dsoc.operator.cuid",
  },
  {
    path: "/dsoc/sodetail",
    name: "dsoc.sodetail",
  },
  {
    path: "/manage",
    icon: "manage",
    name: "manage",
  },
  {
    path: "/manage/customer",
    name: "manage.customer",
  },
  {
    path: "/manage/customer/:customer_id",
    name: "manage.customer.customer_id",
  },
  {
    path: "/manage/customer/new",
    name: "manage.customer.new",
  },
  {
    path: "/manage/site",
    name: "manage.site",
  },
  {
    path: "/manage/site/:site_id",
    name: "manage.site.site_id",
  },
  {
    path: "/manage/site/new",
    name: "manage.site.new",
  },
  {
    path: "/manage/account",
    name: "manage.account",
  },
  {
    path: "/manage/account/:account_id",
    name: "manage.account.account_id",
  },
  {
    path: "/manage/account/new",
    name: "manage.account.new",
  },
  {
    path: "/log",
    icon: "log",
    name: "log",
  },
  {
    path: "/log/guide",
    name: "log.guide",
  },
  {
    path: "/log/status",
    name: "log.status",
  },
  {
    path: "/cubot",
    icon: "cubot",
    name: "cubot",
  },
  {
    path: "/cubot/control",
    name: "cubot.control",
  },
  {
    path: "/cubot/performances",
    name: "cubot.performances",
  },
  {
    path: "/cubot/performances/general",
    name: "cubot.performances.general",
  },
  {
    path: "/cubot/performances/general/service",
    name: "cubot.performances.general.service",
  },
  {
    path: "/cubot/performances/general/view",
    name: "cubot.performances.general.view",
  },
  {
    path: "/cubot/performances/general/revenue",
    name: "cubot.performances.general.revenue",
  },
  {
    path: "/cubot/performances/general/conversion",
    name: "cubot.performances.general.conversion",
  },
  {
    path: "/cubot/performances/faq",
    name: "cubot.performances.faq",
  },
  {
    path: "/cubot/performances/faq/service",
    name: "cubot.performances.faq.service",
  },
  {
    path: "/cubot/performances/faq/contents",
    name: "cubot.performances.faq.contents",
  },
];

const adminMenuList: MenuList = [
  {
    name: "home",
    icon: "home",
    key: "0",
    path: "/home",
  },
  {
    name: "report",
    icon: "report",
    key: "1",
    path: "/report",
    children: [
      {
        name: "report.view",
        key: "1-0",
        path: "/report/view",
      },
      {
        name: "report.revenue",
        key: "1-1",
        path: "/report/revenue",
      },
      {
        name: "report.conversion",
        key: "1-2",
        path: "/report/conversion",
      },
      {
        name: "report.daily",
        key: "1-3",
        path: "/report/daily",
      },
      {
        name: "report.section",
        key: "1-4",
        path: "/report/section",
      },
    ],
  },
  {
    name: "product-report",
    icon: "product",
    key: "8",
    path: "/product-report",
  },
  {
    name: "demo",
    icon: "demo",
    key: "2",
    path: "/demo",
    children: [
      {
        name: "demo.rec",
        key: "2-0",
        path: "/demo/rec",
      },
      {
        name: "demo.rts",
        key: "2-1",
        path: "/demo/rts",
      },
      {
        name: "demo.persona",
        key: "2-2",
        path: "/demo/persona",
      },
      {
        name: "demo.dsoc",
        key: "2-3",
        path: "/demo/dsoc",
      },
    ],
  },
  {
    name: "blog-automation",
    icon: "blog",
    key: "3",
    path: "/blog-automation",
    children: [
      {
        name: "blog-automation.tools",
        key: "3-0",
        path: "/blog-automation/tools",
      },
      {
        name: "blog-automation.labelsTemplate",
        key: "3-0",
        path: "/blog-automation/labelTemplate",
      },
    ],
  },
  {
    name: "service",
    icon: "service",
    key: "4",
    path: "/service",
    children: [
      {
        name: "service.rec-exclude",
        key: "4-0",
        path: "/service/rec-exclude",
      },
      {
        name: "service.api-key",
        key: "4-1",
        path: "/service/api-key",
      },
    ],
  },
  {
    name: "dsoc",
    icon: "dsoc",
    key: "5",
    path: "/dsoc",
    children: [
      {
        name: "dsoc.campaign",
        key: "5-0",
        path: "/dsoc/campaign",
      },
      {
        name: "dsoc.designer",
        key: "5-1",
        path: "/dsoc/designer",
      },
      {
        name: "dsoc.selector",
        key: "5-2",
        path: "/dsoc/selector",
      },
      {
        name: "dsoc.operator",
        key: "5-3",
        path: "/dsoc/operator",
      },
    ],
  },
  {
    name: "manage",
    icon: "manage",
    key: "6",
    path: "/manage",
    children: [
      {
        name: "manage.customer",
        key: "6-0",
        path: "/manage/customer",
      },
      {
        name: "manage.site",
        key: "6-1",
        path: "/manage/site",
      },
      {
        name: "manage.account",
        key: "6-2",
        path: "/manage/account",
      },
    ],
  },
  {
    name: "log",
    icon: "log",
    key: "7",
    path: "/log",
    children: [
      {
        name: "log.guide",
        key: "7-0",
        path: "/log/guide",
      },
      {
        name: "log.status",
        key: "7-1",
        path: "/log/status",
      },
    ],
  },
  {
    name: "cubot",
    icon: "cubot",
    key: "8",
    path: "/cubot",
    children: [
      {
        name: "cubot.control",
        key: "8-0",
        path: "/cubot/control",
      },
      {
        name: "cubot.performances",
        key: "8-1",
        path: "/cubot/performances",
        children: [
          {
            name: "/cubot/performances/general",
            icon: "cubot",
            key: "8-1-1",
            path: "/cubot/performances/general",
            children: [
              {
                name: "/cubot/performances/general/service",
                icon: "cubot",
                key: "8-1-1-1",
                path: "/cubot/performances/general/service",
              },
              {
                name: "/cubot/performances/general/view",
                icon: "cubot",
                key: "8-1-1-2",
                path: "/cubot/performances/general/view",
              },
              {
                name: "/cubot/performances/general/revenue",
                icon: "cubot",
                key: "8-1-1-3",
                path: "/cubot/performances/general/revenue",
              },
              {
                name: "/cubot/performances/general/conversion",
                icon: "cubot",
                key: "8-1-1-4",
                path: "/cubot/performances/general/conversion",
              },
            ],
          },
          {
            name: "/cubot/performances/faq",
            icon: "cubot",
            key: "8-1-2",
            path: "/cubot/performances/faq",
            children: [
              {
                name: "/cubot/performances/faq/service",
                icon: "cubot",
                key: "8-1-2-1",
                path: "/cubot/performances/faq/service",
              },
              {
                name: "/cubot/performances/faq/contents",
                icon: "cubot",
                key: "8-1-2-2",
                path: "/cubot/performances/faq/contents",
              },
            ],
          },
        ],
      },
    ],
  },
];

const customerMenuList: MenuList = [
  {
    name: "home",
    icon: "home",
    key: "0",
    path: "/home",
  },
  {
    name: "report",
    icon: "report",
    key: "1",
    path: "/report",
    children: [
      {
        name: "report.view",
        key: "1-0",
        path: "/report/view",
      },
      {
        name: "report.revenue",
        key: "1-1",
        path: "/report/revenue",
      },
      {
        name: "report.conversion",
        key: "1-2",
        path: "/report/conversion",
      },
      {
        name: "report.daily",
        key: "1-3",
        path: "/report/daily",
      },
      {
        name: "report.section",
        key: "1-4",
        path: "/report/section",
      },
    ],
  },
  {
    name: "demo",
    icon: "demo",
    key: "2",
    path: "/demo",
    children: [
      {
        name: "demo.rec",
        key: "2-0",
        path: "/demo/rec",
      },
      {
        name: "demo.rts",
        key: "2-1",
        path: "/demo/rts",
      },
      {
        name: "demo.persona",
        key: "2-2",
        path: "/demo/persona",
      },
      // {
      //   name: "demo.dsoc",
      //   key: "2-3",
      //   path: "/demo/dsoc",
      // },
    ],
  },
  {
    name: "service",
    icon: "service",
    key: "3",
    path: "/service",
    children: [
      // {
      //   name: "service.rec-exclude",
      //   key: "3-0",
      //   path: "/service/rec-exclude",
      // },
      {
        name: "service.api-key",
        key: "3-1",
        path: "/service/api-key",
      },
    ],
  },
  {
    name: "log",
    icon: "log",
    key: "4",
    path: "/log",
    children: [
      {
        name: "log.guide",
        key: "4-0",
        path: "/log/guide",
      },
    ],
  },
  {
    name: "cubot",
    icon: "cubot",
    key: "5",
    path: "/cubot",
    children: [
      // {
      //   name: "cubot.control",
      //   key: "5-0",
      //   path: "/cubot/control",
      // },
      {
        name: "cubot.performances",
        key: "5-1",
        path: "/cubot/performances",
        children: [
          {
            name: "/cubot/performances/general",
            icon: "cubot",
            key: "5-1-1",
            path: "/cubot/performances/general",
            children: [
              {
                name: "/cubot/performances/general/service",
                icon: "cubot",
                key: "5-1-1-1",
                path: "/cubot/performances/general/service",
              },
              {
                name: "/cubot/performances/general/view",
                icon: "cubot",
                key: "5-1-1-2",
                path: "/cubot/performances/general/view",
              },
              {
                name: "/cubot/performances/general/revenue",
                icon: "cubot",
                key: "5-1-1-3",
                path: "/cubot/performances/general/revenue",
              },
              {
                name: "/cubot/performances/general/conversion",
                icon: "cubot",
                key: "5-1-1-4",
                path: "/cubot/performances/general/conversion",
              },
            ],
          },
          {
            name: "/cubot/performances/faq",
            icon: "cubot",
            key: "5-1-2",
            path: "/cubot/performances/faq",
            children: [
              {
                name: "/cubot/performances/faq/service",
                icon: "cubot",
                key: "5-1-2-1",
                path: "/cubot/performances/faq/service",
              },
              {
                name: "/cubot/performances/faq/contents",
                icon: "cubot",
                key: "5-1-2-2",
                path: "/cubot/performances/faq/contents",
              },
            ],
          },
        ],
      },
    ],
  },
];

const getMenuListByAuthority = (authority: Authority) =>
  authority === ADMIN ? adminMenuList : customerMenuList;

export default getMenuListByAuthority;

import { useComponentId, useReportData } from "hooks";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  RevenueRCCodeStatsDataSelector,
  RevenueStatsDataSelector,
} from "store/modules/report";
import { reportAsyncAction } from "store/modules/report/saga";
import _ from "lodash";
import toFixed from "utils/toFixed";
import { useIntl } from "react-intl";

const useReportRevenueData = () => {
  const componentId = useComponentId();
  const { formatMessage } = useIntl();
  const { startDate, endDate, channel, unit } = useReportData();
  const dispatch = useAppDispatch();

  const [statsDataLoading, statsData] = [
    useAppSelector(RevenueStatsDataSelector.loading),
    useAppSelector(RevenueStatsDataSelector.data),
  ];

  const [rcCodeStatsDataLoading, rcCodeStatsData] = [
    useAppSelector(RevenueRCCodeStatsDataSelector.loading),
    useAppSelector(RevenueRCCodeStatsDataSelector.data),
  ];

  const getAllRevenueStats = useCallback(
    ({ cuid }: { cuid: string }) => {
      dispatch(
        reportAsyncAction.getAllRevenueStats.request({
          startDate,
          endDate,
          cuid,
          channel,
          unit,
        })
      );
    },
    [dispatch, channel, startDate, endDate, unit]
  );

  const {
    orderCountTableData,
    orderCountRatioData,
    orderCountRcCodesList,
    orderCountRatioByRCCodeData,
    orderCountTransitionData,
    revenueTableData,
    revenueRatioData,
    revenueRatioByRCCodeData,
    revenueRcCodesList,
    revenueTransitionData,
    averageOrderRcCodesList,
    averageOrderTableData,
    averageOrderCountData,
    averageOrderPriceData,
    averageOrderTransitionData,
  } = useMemo(() => {
    const removeDuplicateDates = (data: any) => {
      const dateMap = new Map();

      data?.forEach(({ date, ...rest }: any) => {
        if (!dateMap.has(date)) {
          dateMap.set(date, { date, ...rest });
        }
      });

      return Array.from(dateMap.values());
    };

    const duplicationStatsData = removeDuplicateDates(statsData);
    const orderCountTableData = _.map(
      duplicationStatsData,
      (
        { date, values: { orderCount, noOrderCount, noClickOrderCount } },
        idx
      ) => {
        return {
          key: `${componentId}-order-count-table-data-${idx}`,
          date,
          rcCodes: _.reduce(
            _.filter(rcCodeStatsData, (rcCodeStat) => rcCodeStat.date === date),
            (rcCodes, { values }) => {
              rcCodes[values.rcCode] =
                _.defaultTo(_.get(rcCodes, values.rcCode), 0) +
                values.orderCount;
              return rcCodes;
            },
            {} as { [key: string]: number }
          ),
          orderCount,
          noOrderCount,
          noClickOrderCount,
          totalOrderCount: orderCount + noOrderCount + noClickOrderCount,
        };
      }
    );

    const revenueTableData = _.map(
      statsData,
      ({ date, values: { revenue, noRevenue, noClickRevenue } }, idx) => {
        return {
          key: `${componentId}-revenue-table-data-${idx}`,
          date,
          rcCodes: _.reduce(
            _.filter(rcCodeStatsData, (rcCodeStat) => rcCodeStat.date === date),
            (rcCodes, { values }) => {
              rcCodes[values.rcCode] =
                _.defaultTo(_.get(rcCodes, values.rcCode), 0) + values.revenue;
              return rcCodes;
            },
            {} as { [key: string]: number }
          ),
          revenue,
          noRevenue,
          noClickRevenue,
          totalRevenue: revenue + noRevenue + noClickRevenue,
        };
      }
    );

    const averageOrderTableData = _.map(
      statsData,
      (
        {
          date,
          values: {
            revenue,
            orderCount,
            noRevenue,
            noOrderCount,
            noClickRevenue,
            noClickOrderCount,
            totalPurchaseCount,
            noTotalPurchaseCount,
            noClickTotalPurchaseCount,
          },
        },
        idx
      ) => {
        return {
          key: `${componentId}-average-order-table-data-${idx}`,
          date,
          averageRevenuePrice: _.defaultTo(
            _.toNumber(toFixed(0)(revenue / orderCount)),
            0
          ),
          averageNormalRevenuePrice: _.defaultTo(
            _.toNumber(toFixed(0)(noRevenue / noOrderCount)),
            0
          ),
          averageNoClickRevenuePrice: _.defaultTo(
            _.toNumber(toFixed(0)(noClickRevenue / noClickOrderCount)),
            0
          ),
          averageTotalRevenuePrice: _.defaultTo(
            _.toNumber(
              toFixed(0)(
                (revenue + noRevenue + noClickRevenue) /
                  (orderCount + noOrderCount + noClickOrderCount)
              )
            ),
            0
          ),
          orderRatio: _.defaultTo(
            _.toNumber(toFixed(3)(totalPurchaseCount / orderCount)),
            0
          ),
          normalOrderRatio: _.defaultTo(
            _.toNumber(toFixed(3)(noTotalPurchaseCount / noOrderCount)),
            0
          ),
          noClickOrderRatio: _.defaultTo(
            _.toNumber(
              toFixed(3)(noClickTotalPurchaseCount / noClickOrderCount)
            ),
            0
          ),
          totalOrderRatio: _.defaultTo(
            _.toNumber(
              toFixed(3)(
                (totalPurchaseCount +
                  noTotalPurchaseCount +
                  noClickTotalPurchaseCount) /
                  (orderCount + noOrderCount + noClickOrderCount)
              )
            ),
            0
          ),
          rcCodes: _.reduce(
            _.map(
              _.entries(
                _.reduce(
                  _.filter(
                    rcCodeStatsData,
                    (rcCodeStat) => rcCodeStat.date === date
                  ),
                  (rcCodes, { values }) => {
                    rcCodes[values.rcCode] = {
                      revenue:
                        _.defaultTo(
                          _.get(rcCodes, [values.rcCode, "revenue"]),
                          0
                        ) + values.revenue,
                      orderCount:
                        _.defaultTo(
                          _.get(rcCodes, [values.rcCode, "orderCount"]),
                          0
                        ) + values.orderCount,
                    };
                    return rcCodes;
                  },
                  {} as { [key: string]: any }
                )
              ),
              ([key, value]) => {
                return {
                  [key]: _.defaultTo(
                    _.toNumber(toFixed(0)(value.revenue / value.orderCount)),
                    0
                  ),
                };
              }
            ),
            (rcCodes, rcCode) => {
              return { ...rcCodes, ...rcCode };
            },
            {}
          ),
        };
      }
    );

    const {
      averageTotalRevenuePricesLimit,
      averageNormalRevenuePriceLimit,
      averageNoClickRevenuePriceLimit,
      averageTotalRevenuePriceLimit,
      orderRatioLimit,
      normalOrderRatioLimit,
      noClickOrderRatioLimit,
      totalOrderRatioLimit,
    } = averageOrderTableData.reduce(
      (result, el) => {
        if (el.averageRevenuePrice > 0)         result.averageTotalRevenuePricesLimit++;
        if (el.averageNormalRevenuePrice > 0)   result.averageNormalRevenuePriceLimit++;
        if (el.averageNoClickRevenuePrice > 0)  result.averageNoClickRevenuePriceLimit++;
        if (el.averageTotalRevenuePrice > 0)    result.averageTotalRevenuePriceLimit++;
        if (el.orderRatio > 0)                  result.orderRatioLimit++;
        if (el.normalOrderRatio > 0)            result.normalOrderRatioLimit++;
        if (el.totalOrderRatio > 0)             result.totalOrderRatioLimit++;
        if (el.noClickOrderRatio > 0)           result.noClickOrderRatioLimit++;
        return result;
      },
      {
        averageTotalRevenuePricesLimit: 0,
        averageNormalRevenuePriceLimit: 0,
        averageNoClickRevenuePriceLimit: 0,
        averageTotalRevenuePriceLimit: 0,
        orderRatioLimit: 0,
        normalOrderRatioLimit: 0,
        totalOrderRatioLimit: 0,
        noClickOrderRatioLimit: 0,
      }
    );

    const orderCountRatioData = _.map(
      _.entries(
        _.reduce(
          orderCountTableData,
          (types, { orderCount, noOrderCount, noClickOrderCount }) => {
            return {
              ...types,
              orderCount: _.get(types, "orderCount", 0) + orderCount,
              noOrderCount: _.get(types, "noOrderCount", 0) + noOrderCount,
              noClickOrderCount:
                _.get(types, "noClickOrderCount", 0) + noClickOrderCount,
            };
          },
          {} as { [key: string]: number }
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const revenueRatioData = _.map(
      _.entries(
        _.reduce(
          revenueTableData,
          (types, { revenue, noRevenue, noClickRevenue }) => {
            return {
              ...types,
              revenue: _.get(types, "revenue", 0) + revenue,
              noRevenue: _.get(types, "noRevenue", 0) + noRevenue,
              noClickRevenue:
                _.get(types, "noClickRevenue", 0) + noClickRevenue,
            };
          },
          {} as { [key: string]: number }
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const orderCountRcCodesWithValue = _.sortBy(
      _.map(
        _.entries(
          _.reduce(
            orderCountTableData,
            (rcCodesData, { rcCodes }) => {
              return _.mergeWith(
                rcCodesData,
                rcCodes,
                (originValue, incomeValue) => {
                  originValue = (originValue || 0) + incomeValue;
                  return originValue;
                }
              );
            },

            {} as { [key: string]: number }
          )
        ),
        ([key, value]) => ({ name: key, value })
      ),
      ["name"]
    ).sort((a, b) => {
      const { name: aName } = a;
      const { name: bName } = b;
      if (aName && bName) {
        const aNum = Number(aName.match(/(\d+)/g));
        const bNum = Number(bName.match(/(\d+)/g));
        return aNum - bNum;
      }
      return (a.name as any) - (b.name as any);
    });

    const orderCountRcCodesWithValueSortByValue = _.reverse(
      _.sortBy(
        _.map(
          _.entries(
            _.reduce(
              orderCountTableData,
              (rcCodesData, { rcCodes }) => {
                return _.mergeWith(
                  rcCodesData,
                  rcCodes,
                  (originValue, incomeValue) => {
                    originValue = (originValue || 0) + incomeValue;
                    return originValue;
                  }
                );
              },

              {} as { [key: string]: number }
            )
          ),
          ([key, value]) => ({ name: key, value })
        ),
        ["value"]
      )
    );

    const revenueRcCodesWithValue = _.sortBy(
      _.map(
        _.entries(
          _.reduce(
            revenueTableData,
            (rcCodesData, { rcCodes }) => {
              return _.mergeWith(
                rcCodesData,
                rcCodes,
                (originValue, incomeValue) => {
                  originValue = (originValue || 0) + incomeValue;
                  return originValue;
                }
              );
            },

            {} as { [key: string]: number }
          )
        ),
        ([key, value]) => ({ name: key, value })
      ),
      ["name"]
    ).sort((a, b) => {
      const { name: aName } = a;
      const { name: bName } = b;
      if (aName && bName) {
        const aNum = Number(aName.match(/(\d+)/g));
        const bNum = Number(bName.match(/(\d+)/g));
        return aNum - bNum;
      }
      return (a.name as any) - (b.name as any);
    });

    const revenueRcCodesWithValueSortByValue = _.reverse(
      _.sortBy(
        _.map(
          _.entries(
            _.reduce(
              revenueTableData,
              (rcCodesData, { rcCodes }) => {
                return _.mergeWith(
                  rcCodesData,
                  rcCodes,
                  (originValue, incomeValue) => {
                    originValue = (originValue || 0) + incomeValue;
                    return originValue;
                  }
                );
              },

              {} as { [key: string]: number }
            )
          ),
          ([key, value]) => ({ name: key, value })
        ),
        ["value"]
      )
    );

    const averageOrderRcCodesWithValue = _.sortBy(
      _.map(
        _.entries(
          _.reduce(
            averageOrderTableData,
            (rcCodesData, { rcCodes }) => {
              return _.mergeWith(
                rcCodesData,
                rcCodes,
                (originValue, incomeValue) => {
                  originValue = (originValue || 0) + incomeValue;
                  return originValue;
                }
              );
            },

            {} as { [key: string]: number }
          )
        ),
        ([key, value]) => ({ name: key, value })
      ),
      ["name"]
    ).sort((a, b) => {
      const { name: aName } = a;
      const { name: bName } = b;
      if (aName && bName) {
        const aNum = Number(aName.match(/(\d+)/g));
        const bNum = Number(bName.match(/(\d+)/g));
        return aNum - bNum;
      }
      return (a.name as any) - (b.name as any);
    });

    const averageOrderRcCodesWithValueSortByValue = _.reverse(
      _.sortBy(
        _.map(
          _.entries(
            _.reduce(
              averageOrderTableData,
              (rcCodesData, { rcCodes }) => {
                return _.mergeWith(
                  rcCodesData,
                  rcCodes,
                  (originValue, incomeValue) => {
                    originValue = (originValue || 0) + incomeValue;
                    return originValue;
                  }
                );
              },

              {} as { [key: string]: number }
            )
          ),
          ([key, value]) => ({ name: key, value })
        ),
        ["value"]
      )
    );

    const orderCountRcCodesList = _.map(
      orderCountRcCodesWithValue,
      ({ name }) => name
    );

    const revenueRcCodesList = _.map(
      revenueRcCodesWithValue,
      ({ name }) => name
    );

    const averageOrderRcCodesList = _.map(
      averageOrderRcCodesWithValue,
      ({ name }) => name
    );

    const orderCountRatioByRCCodeData = _.filter(
      [
        ..._.slice(orderCountRcCodesWithValueSortByValue, 0, 5),
        _.reduce(
          _.slice(orderCountRcCodesWithValueSortByValue, 5),
          (etc, { value }) => {
            etc["value"] = _.get(etc, "value", 0) + value;
            return { name: "ETC", value: etc["value"] };
          },
          {} as { [key: string]: any }
        ),
      ],
      _.negate(_.isEmpty)
    );

    const revenueRatioByRCCodeData = _.filter(
      [
        ..._.slice(revenueRcCodesWithValueSortByValue, 0, 5),
        _.reduce(
          _.slice(revenueRcCodesWithValueSortByValue, 5),
          (etc, { value }) => {
            etc["value"] = _.get(etc, "value", 0) + value;
            return { name: "ETC", value: etc["value"] };
          },
          {} as { [key: string]: any }
        ),
      ],
      _.negate(_.isEmpty)
    );

    const averageOrderCountData = _.map(
      _.entries(
        _.reduce(
          averageOrderTableData,
          (
            types,
            {
              orderRatio,
              normalOrderRatio,
              noClickOrderRatio,
              totalOrderRatio,
            },
            idx
          ) => {
            return {
              ...types,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.rec",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(3)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.rec",
                            }),
                            0
                          ) +
                            orderRatio) /
                            (orderRatioLimit == 0
                              ? averageOrderTableData.length
                              : orderRatioLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.rec",
                      }),
                      0
                    ) + orderRatio,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.normal",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(3)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.normal",
                            }),
                            0
                          ) +
                            normalOrderRatio) /
                            (normalOrderRatioLimit == 0
                              ? averageOrderTableData.length
                              : normalOrderRatioLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.normal",
                      }),
                      0
                    ) + normalOrderRatio,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.noClick",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(3)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.noClick",
                            }),
                            0
                          ) +
                            noClickOrderRatio) /
                            (noClickOrderRatioLimit == 0
                              ? averageOrderTableData.length
                              : noClickOrderRatioLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.noClick",
                      }),
                      0
                    ) + noClickOrderRatio,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.total",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(3)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.total",
                            }),
                            0
                          ) +
                            totalOrderRatio) /
                            (totalOrderRatioLimit == 0
                              ? averageOrderTableData.length
                              : totalOrderRatioLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderCountCard.xAxis.total",
                      }),
                      0
                    ) + totalOrderRatio,
            };
          },
          {}
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const orderCountData = removeDuplicateDates(orderCountTableData);

    const orderCountTransitionData = _.map(
      orderCountTableData,
      ({ date, orderCount, noOrderCount, noClickOrderCount }) => {
        return {
          name: date,
          orderCount,
          noOrderCount,
          noClickOrderCount,
          totalOrderCount: orderCount + noOrderCount + noClickOrderCount,
        };
      }
    );

    const revenueTransitionData = _.map(
      revenueTableData,
      ({ date, revenue, noRevenue, noClickRevenue, totalRevenue }) => {
        return {
          name: date,
          revenue,
          noRevenue,
          noClickRevenue,
          totalRevenue,
        };
      }
    );

    const averageOrderPriceData = _.map(
      _.entries(
        _.reduce(
          averageOrderTableData,
          (
            types,
            {
              averageRevenuePrice,
              averageNormalRevenuePrice,
              averageNoClickRevenuePrice,
              averageTotalRevenuePrice,
            },
            idx
          ) => {
            return {
              ...types,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.rec",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(0)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.rec",
                            }),
                            0
                          ) +
                            averageRevenuePrice) /
                            (averageTotalRevenuePricesLimit == 0
                              ? averageOrderTableData.length
                              : averageTotalRevenuePricesLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.rec",
                      }),
                      0
                    ) + averageRevenuePrice,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.normal",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(0)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.normal",
                            }),
                            0
                          ) +
                            averageNormalRevenuePrice) /
                            (averageNormalRevenuePriceLimit == 0
                              ? averageOrderTableData.length
                              : averageNormalRevenuePriceLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.normal",
                      }),
                      0
                    ) + averageNormalRevenuePrice,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.noClick",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(0)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.noClick",
                            }),
                            0
                          ) +
                            averageNoClickRevenuePrice) /
                            (averageNoClickRevenuePriceLimit == 0
                              ? averageOrderTableData.length
                              : averageNoClickRevenuePriceLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.noClick",
                      }),
                      0
                    ) + averageNoClickRevenuePrice,
              [formatMessage({
                id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.total",
              })]:
                idx + 1 === averageOrderTableData.length
                  ? _.defaultTo(
                      _.toNumber(
                        toFixed(0)(
                          (_.get(
                            types,
                            formatMessage({
                              id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.total",
                            }),
                            0
                          ) +
                            averageTotalRevenuePrice) /
                            (averageTotalRevenuePriceLimit == 0
                              ? averageOrderTableData.length
                              : averageTotalRevenuePriceLimit)
                        )
                      ),
                      0
                    )
                  : _.get(
                      types,
                      formatMessage({
                        id: "Components.Report.Revenue.AverageOrderPriceCard.xAxis.total",
                      }),
                      0
                    ) + averageTotalRevenuePrice,
            };
          },
          {}
        )
      ),
      ([key, value]) => ({ name: key, value })
    );

    const averageOrderTransitionData = _.map(
      averageOrderTableData,
      ({
        date,
        averageRevenuePrice,
        averageNormalRevenuePrice,
        averageTotalRevenuePrice,
        averageNoClickRevenuePrice,
      }) => {
        return {
          name: date,
          averageRevenuePrice,
          averageNormalRevenuePrice,
          averageTotalRevenuePrice,
          averageNoClickRevenuePrice,
        };
      }
    );

    return {
      orderCountTableData,
      orderCountRcCodesList,
      orderCountRatioData,
      orderCountRatioByRCCodeData,
      orderCountTransitionData,
      revenueRcCodesList,
      revenueTableData,
      revenueRatioData,
      revenueRatioByRCCodeData,
      revenueTransitionData,
      averageOrderRcCodesList,
      averageOrderTableData,
      averageOrderCountData,
      averageOrderPriceData,
      averageOrderTransitionData,
    };
  }, [statsData, rcCodeStatsData, componentId, formatMessage]);

  return {
    statsDataLoading,
    rcCodeStatsDataLoading,
    orderCountTableData,
    orderCountRatioData,
    orderCountRatioByRCCodeData,
    orderCountTransitionData,
    orderCountRcCodesList,
    revenueTableData,
    revenueRatioData,
    revenueRatioByRCCodeData,
    revenueRcCodesList,
    revenueTransitionData,
    averageOrderRcCodesList,
    averageOrderTableData,
    averageOrderCountData,
    averageOrderPriceData,
    averageOrderTransitionData,
    getAllRevenueStats,
  };
};

export default useReportRevenueData;
